.Badges {
    .HeaderButtonWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .Icon {
            background-color: rgba(120, 155, 234, 0.18);
            padding: 8px;
            border-radius: 6px;
            color: rgba(120, 155, 234, 0.8);
        }
        .Button {
            display: flex;
            justify-content: flex-end;
            color: white;
            cursor: pointer;
        }
    }
}
