

.ChangeUserGroupPopUp {
    position: relative;
    width: 400px;
    text-align: left;

    .close-icon {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
    }

    h1 {
        margin: 0;
        padding: 0;
        padding-right: 48px;
        width: 100%;
    }

    input[type="text"],
    input[type="date"],
    input[type="time"],
    input[type="number"],
    input[type="tel"],
    textarea {
      width: 100%;
      font-size: 14px;
      padding: 8px 12px;
      border-radius: 4px;
      border: solid 1px ;
    }

    #userID {
        font-size: 12px;
        margin-top: 4px;
        opacity: 0.25
    }

    h4 {
        margin-top: 24px;
        margin-bottom: 4px;
    }

    ul {
        padding-left: 24px;
        overflow-y: auto;
        max-height: 120px;
    }

    .buttons {
        display: grid;
        grid-auto-flow: row;

        button {
            width: 100%;
            background-color: blue;
            border: none;
            color: white;
            padding: 8px 16px;
            font-size: 16px;
            margin-top: 24px;
            border-radius: 4px;
            cursor: pointer;
        }
        
        .delete-button {
            background-color: red;
            border: none;
            color: white;
            padding: 8px 16px;
            font-size: 16px;
            margin-top: 24px;
            border-radius: 4px;
            cursor: pointer;

            &:disabled {
                background-color: gray;
                color: rgba(255,255,255,0.5);
                cursor: not-allowed;
            }
        }
    }
}