.GroupItemContainer {
    display: flex;
    flex-direction: row;
    position: relative;
    .GroupItem {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        height: 40px;
        width: 100%;
        background-color: rgba(120, 155, 234, 0.16);
        margin: 8px 0;
        border-radius: 12px;
        .GroupColumn {
            display: flex;
            width: 100%;
            justify-content: space-around;
            font-weight: 600;
            color: rgba(120, 155, 234, 0.8);
        }
        .BigTeamIdentifier {
            position: absolute;
            top: 0;
            right: 0;
            background-color:  #ffbc70;
            padding: 4px;
            border-radius: 55px;
            color: #F9DCC4;
        }
    }
}
