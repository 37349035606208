$side-bar-width: 74px;
$header-height: 50px;

// Colors
$amp-green: #9ac631;
$amp-blue: #00aae8;
$amp-orange: #f6891b;
$amp-yellow: #fff006;
$background-color: #161320;
$border-color: rgba(0, 0, 0, 0.1);
$inactive-color: #b8bdcc;
$error-color: #d04640;
$dark-text-color: #313344;

.sidebar {
  background-color: #161320;
  width: $side-bar-width;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto 1fr auto;

  .head {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: $header-height;
    padding: 8px;
    text-align: center;
    border-bottom: solid 1px $border-color;

    .image {
      height: 100%;
    }
  }

  .navbar {
    padding-top: 8px;
    border-right: solid 1px $border-color;

    ul {
      width: 100%;
    }
  }

  .foot {
    padding: 12px 0;
    width: 100%;
    border-top: solid 1px $border-color;
    border-right: solid 1px $border-color;

    .footUl {
      width: 100%;

      .footLi {
        width: 100%;

        .footButton {
          width: 100%;
          text-align: center;
          background-color: transparent;
          border: none;
          cursor: pointer;

          i {
            transform: rotate(180deg);
            color: $error-color;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
