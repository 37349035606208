$side-bar-width: 74px;
$header-height: 50px;

// Colors
$amp-green: #9ac631;
$amp-blue: #00aae8;
$amp-orange: #f6891b;
$amp-yellow: #fff006;
$background-color: #000000;
$border-color: rgba(0,0,0,0.1);
$inactive-color: #b8bdcc;
$error-color: #d04640;
$dark-text-color: #313344;

$font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

.popup {
    background-color: rgba(0,0,0,0.3);
    backdrop-filter: blur(8px) brightness(90%);
    position: fixed;
    top: 0;
    left: $side-bar-width;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    font-family: $font;
    // display: none;

    .content {
        background-color: rgb(26, 25, 25);
        color: rgb(255, 255, 255);
        border: solid 1px $border-color;
        border-radius: 8px;
        padding: 24px;
        text-align: center;
        box-shadow: 0 10px 45px rgba(0,0,0,0.25);
        max-height: 100%;
        overflow-y: auto;
    }
}