.ButtonContainer {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    font-weight: 600;
}

.content {
    margin-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.share {
    display: inline-block;
    height: 40px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}
.hidden{
	width: 100%;
	height: 100%;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.label{
    cursor: pointer;
}
.buttons{
    background-color: rgba(120, 155, 234, 0.3);
    color: rgba(120, 155, 234, 0.8);
    margin: 4px;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
}
