$side-bar-width: 74px;
$header-height: 50px;

// Colors
$amp-green: #9ac631;
$amp-blue: #00aae8;
$amp-orange: #f6891b;
$amp-yellow: #fff006;
$background-color: #000000;
$border-color: rgba(0,0,0,0.1);
$inactive-color: #b8bdcc;
$error-color: #d04640;
$dark-text-color: #313344;

// Font
$font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


.card {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 6px;

    h1 {
        color: white;
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: lighter;
        padding-left: 2px;
    }

    .content {
        margin-top: 12px;
        color: $dark-text-color;
        background-color: rgba(120, 155, 234, 0.16);
        padding: 24px;
        border: solid 1px $border-color;
        flex: 1;
        border-radius: 4px;
    }
}