$side-bar-width: 74px;
$header-height: 50px;

// Colors
$amp-green: #9ac631;
$amp-blue: #00aae8;
$amp-orange: #f6891b;
$amp-yellow: #fff006;
$background-color: #000000;
$border-color: rgba(0, 0, 0, 0.1);
$inactive-color: #b8bdcc;
$error-color: #d04640;
$dark-text-color: #313344;

.GroupDetailsPopUp {
    position: relative;
    width: 400px;
    text-align: left;
    color: rgb(255, 255, 255);

    .closeIcon {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
    }

    .h1 {
        margin: 0;
        padding: 0;
        padding-right: 48px;
        width: 100%;
        font-weight: 600;
        font-size: 24px;
    }

    .groupID {
        font-size: 12px;
        margin-top: 4px;
        opacity: 0.25;
    }

    .h4 {
        margin-top: 20px;
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 14px;
    }

    .Li {
        font-size: 16px;
        font-weight: 300;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 4px;
        align-items: center;
        .removeUserButton {
            background-color: rgba(245, 140, 136, 0.4);
            color: rgba(245, 140, 136, 1);
            font-weight: 600;
            font-size: 12px;
            padding: 6px 8px;
            border-radius: 6px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 6px;

        .button {
            border: none;
            color: white;
            font-size: 14px;
            margin: 12px;
            cursor: pointer;
            width: 100%;
            font-weight: 600;
            padding: 8px;
            border-radius: 8px;
        }

        .addButton,
        .saveButton {
            background-color: rgba(120, 155, 234, 0.4);
            color: rgba(120, 155, 234, 1);
        }

        .deleteButton {
            background-color: rgba(245, 140, 136, 0.4);
            color: rgba(245, 140, 136, 1);

            &:disabled {
                background-color: gray;
                color: rgba(255, 255, 255, 0.5);
                cursor: not-allowed;
            }
        }
    }
}

// TODO: fuck scss get rid of this bull shit

.input {
    padding: 8px;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    color: white;
    font-size: 14px;
    font-weight: 600;
}