$font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

.ContentHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .ContentText {
        font-size: 42px;
        font-family: $font;
        font-weight: 600;
        color: rgba(120, 155, 234, 0.8);
    }
}