.Analytics {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    
}
.content {
    margin-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
}

.share {
    display: inline-block;
    height: 40px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}
.hidden{
	width: 100%;
	height: 100%;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.label{
    cursor: pointer;
}
.section {
    display: flex;
    color: white;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
}
.date {
    display: flex;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 600;
}
.date input {
    padding: 8px;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    color: white;
    font-size: 14px;
    font-weight: 600;
}
.select {
    display: flex;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 14px;
    font-weight: 600;
}
.select select {
    padding: 8px;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    color: white;
    font-size: 14px;
    font-weight: 600;
}
.download {
    margin-top: 4px;
    padding: 0px 20px;
    height: 40px;
    border-radius: 3px;
    color: white;
    font-weight: 600;
    font-size: 15px;
    background-color: rgba(255, 255, 255, 0.12);
}
.download.busy {
    pointer-events: none;
    opacity: 0.5;
}
.download.disabled {
    pointer-events: none;
    opacity: 0.5;
}
