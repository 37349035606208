// Font
$font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

.GroupItemContainer {
    // background-color: rgba(120, 155, 234, 0.4);
    border-radius: 18px;
    color: rgba(255, 255, 255, 0.7);
    font-family: $font;
    .ContainerHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 8px 8px 0 0;
        font-weight: 600;
        padding: 12px 0;
        margin-top: 18px;
        .TitleColumn {
            display: flex;
            width: 100%;
            justify-content: space-around;
        }
    }
    .ItemWrapper {
        display: flex;
        flex-direction: column;
    }
}