$side-bar-width: 74px;
$header-height: 50px;

// Colors
$amp-green: #9ac631;
$amp-blue: #00aae8;
$amp-orange: #f6891b;
$amp-yellow: #fff006;
$background-color: #000000;
$border-color: rgba(0,0,0,0.1);
$inactive-color: #b8bdcc;
$error-color: #d04640;
$dark-text-color: #313344;

// Font
$font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


.GeneralCard {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  align-items: stretch;
  justify-content: stretch;

  textarea {
    resize: none;
    background-color: rgba(0, 0, 0, 0.005);
    border: solid 1px $border-color;
    border-radius: 4px;
    padding: 12px;
    font-size: 12px;
    width: 100%;
    height: 100%;
    min-height: 200px;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      padding: 8px 24px;
      font-size: 12px;
      background-color: $amp-blue;
      border: none;
      border-radius: 4px;
      color: white;
      margin-top: 8px;
      cursor: pointer;

      &:disabled {
        // background-color: gray;
        // color: rgba(255, 255, 255, 0.5);
        opacity: 0.25;
        cursor: not-allowed;
      }
    }
  }
}
