$side-bar-width: 74px;
$header-height: 50px;

// Colors
$amp-green: #9ac631;
$amp-blue: #00aae8;
$amp-orange: #f6891b;
$amp-yellow: #fff006;
$background-color: #000000;
$border-color: rgba(0,0,0,0.1);
$inactive-color: #b8bdcc;
$error-color: #d04640;
$dark-text-color: #313344;

// Font
$font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


.DailyMotivationCard {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  font-size: 12px;
  justify-content: stretch;
  align-items: stretch;
  font-family: $font;

  h1 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: white;
    grid-column: 1;
    grid-row: 0;

    &:nth-child(2) {
      grid-column: 2;
    }
  }

  .daily-motivation-list-container {
    overflow-y: auto;
    border: solid 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: rgba(255,255,255, 1);
    display: grid;
    grid-template-rows: auto 1fr;
    height: 220px;

    ul {

      li {
        list-style: none;
        border-bottom: solid 1px $border-color;
        border-radius: 4px;
        padding: 8px;
        color: $dark-text-color;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .daily-motivation-container {
    display: grid;
    grid-template-rows: 1fr auto auto;

    p {
      font-size: 12px;
      overflow-y: auto;
      color: $dark-text-color;
    }

    input[type="text"] {
      padding: 8px;
      font-size: 12px;
      background-color: white;
      border: solid 1px $border-color;
      border-radius: 4px;
      color: $dark-text-color;
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button {
        padding: 8px 24px;
        font-size: 12px;
        background-color: $amp-blue;
        border: none;
        border-radius: 4px;
        color: white;
        margin-top: 8px;
        cursor: pointer;
        width: 100%;

        &:disabled {
          // background-color: gray;
          // color: rgba(255, 255, 255, 0.5);
          opacity: 0.25;
          cursor: not-allowed;
        }
      }
    }
  }
}
