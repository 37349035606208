$side-bar-width: 74px;
$header-height: 50px;

// Colors
$amp-green: #9ac631;
$amp-blue: #00aae8;
$amp-orange: #f6891b;
$amp-yellow: #fff006;
$background-color: #000000;
$border-color: rgba(0,0,0,0.1);
$inactive-color: #b8bdcc;
$error-color: #d04640;
$dark-text-color: #313344;

// Font
$font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: $font;
}

body {
  background-color: $background-color;
}

.mainContent {
  position: fixed;
  top: 0;
  left: $side-bar-width;
  right: 0;
  bottom: 0;
  padding: 36px;
  // padding-top: calc(#{$header-height} + 36px);
  padding-top: 36px;
  overflow-y: auto;
}

.login-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;

  .content {
    background-color: #161320;
    border: solid 1px $border-color;
    border-radius: 4px;
    padding: 24px;
    max-width: 400px;
    width: 100%;

    h1 {
      margin: 0;
      padding: 0;
      font-size: 24px;
      margin-bottom: 12px;
    }

    .error-field {
      color: white;
      background-color: $error-color;
      width: 100%;
      padding: 8px 12px;
      border-radius: 4px;
    }

    input[type="text"],
    input[type="password"] {
      font-size: 18px;
      font-weight: 100;
      padding: 8px 12px;
      width: 100%;
      margin-top: 12px;
      border-radius: 4px;
      border: solid 1px $dark-text-color;
      background-color: white;
    }

    button {
      font-size: 18px;
      font-weight: 100;
      padding: 8px 12px;
      width: 100%;
      margin-top: 24px;
      border-radius: 4px;
      border: none;
      color: white;
      background-color: $amp-blue;
      cursor: pointer;
    }
  }
}


.App ::-webkit-scrollbar {
  display: none;
}

.Routes {
  min-height: calc(100vh - 48px - env(safe-area-inset-bottom));
}

.content {
  min-height: calc(100vh - 48px - env(safe-area-inset-bottom));
  padding-bottom: calc(48px + env(safe-area-inset-bottom));
}

.wallContainer {
  min-height: calc(100vh - env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
}

.routeContainer {
  position: relative;
  min-height: calc(100vh - 48px - env(safe-area-inset-bottom));
  overflow: hidden;
  box-sizing: border-box;
}

.App select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  box-sizing: border-box;
}

.noPadding {
  padding: 0px !important;
}

.routeContent {
  display: none;
}

.routeContent.visible {
  display: block;
}


@keyframes sidebarAccentHeightAnimation {
  from {
    top: 50%;
    width: 0;
    bottom: 50%;
  }
  to {
    top: 0;
    width: 4px;
    bottom: 0;
  }
}
