.UserDetailsPopUp {
    position: relative;
    width: 400px;
    text-align: left;

    .close-icon {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
    }

    h1 {
        margin: 0;
        padding: 0;
        padding-right: 48px;
        width: 100%;
        
    }

    #userID {
        font-size: 12px;
        margin-top: 4px;
        opacity: 0.25
    }

    .UserListItem {
        margin: 20px 0;
    }

    h4 {
        margin-top: 24px;
        margin-bottom: 4px;
    }

    ul {
        padding-left: 24px;
        overflow-y: auto;
        max-height: 120px;
    }
    li{
        font-size: 16px;
        font-weight: 300;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 4px;
        align-items: center;
    }
    p{
        font-size: 16px;
        font-weight: 300;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 4px;
        align-items: center;
    }
    .addSteps{
        width: 100%;
        font-size: 14px;
        padding: 8px 12px;
        background-color: rgba(161, 156, 155, 0.1);
        margin: 12px 0;
        font-size: 15px;
        font-weight: 600;
        border-radius: 12px;
        color: white;
    }
    .removeUserButton {
        background-color: rgba(245, 140, 136, 0.4);
        color: rgba(245, 140, 136, 1);
        font-weight: 600;
        font-size: 12px;
        padding: 6px 8px;
        border-radius: 6px;
        cursor: pointer;
    }

     .dateInput {
         width: 50%;
            border-radius: 4px;
            background-color: rgba(161, 156, 155, 0.1);
            padding: 6px;
            color: white;
          }

    .buttons {
        display: grid;
        grid-auto-flow: row;

        button {
            width: 100%;
            background-color: rgba(120, 155, 234, 0.3);
            border: none;
            color: rgba(120, 155, 234, 0.8);
            padding: 8px 16px;
            font-size: 16px;
            margin-top: 24px;
            border-radius: 4px;
            cursor: pointer;
        }
        
        .delete-button {
            background-color: red;
            border: none;
            color: white;
            padding: 8px 16px;
            font-size: 16px;
            margin-top: 12px;
            border-radius: 4px;
            cursor: pointer;

            &:disabled {
                background-color: gray;
                color: rgba(255,255,255,0.5);
                cursor: not-allowed;
            }
        }
    }
}