$font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;

.BadgeCard {
    display: flex;
    flex-direction: column;
    font-family: $font;

    .Information {
        display: flex;
        flex-direction: column;
        h1 {
            font-size: 24px;
            font-weight: 600;
            grid-column: span 2;
            color: rgba(255, 255, 255, 0.9);
        }

        .badgeID {
            font-size: 12px;
            margin-top: 4px;
            opacity: 0.25;
            grid-column: 1;
        }

        h4 {
            margin-top: 12px;
            font-size: 18px;
            font-weight: 300;
            color: rgba(255, 255, 255, 0.8);
        }

        p {
            font-size: 14px;
            font-weight: 100;
            margin-top: 6px;
            color: rgba(255, 255, 255, 0.5);
        }
    }
    .BadgeImage {
        img {
            max-width: 100%;
        }
    }

    .Buttons {
        display: flex;
        justify-content: flex-end;
        grid-column: span 2;

        button {
            text-decoration: none;
            background-color: rgba(120, 155, 234, 0.3);
            color: rgba(120, 155, 234, 0.8);
            border: none;
            padding: 8px 16px;
            font-size: 16px;
            margin-top: 24px;
            border-radius: 4px;
            cursor: pointer;
            width: 100%;
        }
    }
}
