
.SignIn {
    background-color: #161320;
    height: 100vh;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 420px; /* ;) */
    padding: 24px;
    border-radius: 24px;
    background-color: rgba(161, 156, 155, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    transition: 0.5s transform, 0.5s opacity;
}

.container.pending {
    opacity: 0.5;
    pointer-events: none;
}

.container.finished {
    opacity: 0.0;
    transform: scale(1.2);
}

.title {
    margin-top: 24px;
    margin-bottom: 4px;
    font-size: 24px;
    font-weight: 700;
}

.description {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3em;
}

.description a {
    opacity: 0.9;
    text-decoration: underline;
}

.textField {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 700;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    border-radius: 12px;
    background-color: transparent;
    box-sizing: border-box;
    color: white;
    background-color: rgba(161, 156, 155, 0.1);
}

.textField:focus{
    border: .25px solid white;
    transition: all 1s ease-in-out;
}

.submit {
    margin-top: 12px;
    margin-bottom: 12px;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 12px;
    text-align: center;
    cursor: pointer;
    background-color: rgba(120, 155, 234, 0.16);
    color: rgba(120, 155, 234, 0.7);
    font-weight: 700;
}
