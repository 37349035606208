.Modal {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 9;
    opacity: 0;
    transform: translateX(80px);
    transition: 0.3s opacity, 0.5s transform;
}

.Modal.visible {
    transform: translateX(0px);
    opacity: 1;
    pointer-events: auto;
}

.content {
    width: 100%;
    height: 100%;
    padding-top: calc(6px + env(safe-area-inset-top));
    padding-bottom: calc(6px + env(safe-area-inset-bottom));
    box-sizing: border-box;
    overflow-y: scroll;
    /* padding-left: 16px;
    padding-right: 16px; */
}
.groupInput {
    margin-top: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 18px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 18px;
    box-sizing: border-box;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 700;
    color: white;
    background-color: rgba(161, 156, 155, 0.12);
}

.btnInput {
    margin-top: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 24px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 24px;
    box-sizing: border-box;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 700;
    background-color: rgba(161, 156, 155, 0.12);
    cursor: pointer;
}
