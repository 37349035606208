.AddBadgePopUp {
  position: relative;
  width: 500px;
  text-align: left;
  color: white !important;
  display: flex;
  flex-direction: column;

  .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
  }

  .h1 {
    margin: 0;
    padding: 0;
    padding-right: 48px;
    width: 100%;
    font-weight: 600;
    font-size: 24px;
  }

  .input {
    width: 100%;
    font-size: 14px;
    padding: 8px 12px;
    background-color: rgba(161, 156, 155, 0.1);
    margin: 12px 0;
    font-size: 15px;
    font-weight: 700;
    border-radius: 12px;
    color: white !important;
  }

  .select {
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 4px;
    border: solid 1px black;
    background-color: white;
  }

  .textarea {
    height: 90px;
    resize: none;
  }

  .image {
    width: 50%;
    display: block;
    margin-top: 12px;
  }

  .h4 {
    margin-top: 24px;
    margin-bottom: 4px;
    margin-left: 4px;
  }

  .h5 {
    margin-top: 8px;
    margin-bottom: 4px;
    margin-left: 4px;
    opacity: 0.75;
  }

  .imageStatus {
    color: darkblue;
    background-color: rgba(0, 0, 255, 0.25);
    padding: 8px;
    border-radius: 4px;
  }

  .imageError {
    color: darkred;
    background-color: rgba(255, 0, 0, 0.25);
    padding: 8px;
    border-radius: 4px;
  }

  .buttons {
    display: grid;
    justify-content: stretch;
    margin-top: 24px;
    grid-gap: 8px;

    .button {
      color: white;
      border: none;
      padding: 8px 16px;
      font-size: 16px;
      border-radius: 4px;
      cursor: pointer;

      &:disabled {
        // background-color: gray;
        // color: rgba(255, 255, 255, 0.5);
        opacity: 0.25;
        cursor: not-allowed;
      }
    }

    .CancelButton {
      background-color: #7d7d7d;
    }

    .AddButton,
    .SaveButton {
      background-color: blue;
    }

    .DeleteButton {
      background-color: red;
      margin-top: 16px;
    }
  }

  .ImagePicker {
    width: 100%;
    .filePicker {
      color: transparent;
    }
    .filePicker::-webkit-file-upload-button {
      visibility: hidden;
    }
    .filePicker::before {
      content: "Click here to select a Badge Image";
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      font-weight: 600;
      background-color: rgba(161, 156, 155, 0.1);
      border-radius: 12px;
      width: 100%;
      min-height: 200px;
      color: white;
    }
    .filePicker {
      width: 100%;
    }
  }

  .Selector {
    display: flex;
    flex-direction: column;
    .DateContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 6px 0;
      .dateInput {
        border-radius: 4px;
        background-color: rgba(161, 156, 155, 0.1);
        padding: 6px;
        color: white;
      }
      .dateInput::-webkit-calendar-picker-indicator {
      }
    }
  }

  .Requirements {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .LabelContainer {
      padding: 6px 0;
      .valInput {
        width: 100%;
        textarea {
          color: white;
        }
      }
    }
  }
}

.SaveButton {
  background-color: rgba(120, 155, 234, 0.18);
  color: rgba(120, 155, 234, 0.8);
  padding: 12px;
  margin: 6px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 12px 0;
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }
  .slider:before {
    transition: 0.4s;
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 34px;
  }
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  input:checked + .slider {
    background-color: #2196f3;
  }
}
