$side-bar-width: 74px;
$header-height: 50px;

// Colors
$amp-green: #9ac631;
$amp-blue: #00aae8;
$amp-blu: rgba(120, 155, 234, 0.9);
$amp-orange: #f6891b;
$amp-yellow: #fff006;
$background-color: #161320;
$border-color: rgba(0, 0, 0, 0.1);
$inactive-color: #b8bdcc;
$error-color: #d04640;
$dark-text-color: #313344;

li {
  width: 100%;

  a {
    position: relative;
    padding: 12px 24px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 24px 1fr;
    width: 100%;
    text-decoration: none;
    color: $inactive-color;
    opacity: 1;
    transition: color 0.25s ease-in-out, opacity 0.25s ease-in-out;

    &:hover {
      // opacity: 0.5;
      color: $amp-blu;

      .user-hint {
        display: inline-block;
        color: $inactive-color;
      }
    }

    &.is-active {
      color: $amp-blue;

      &:before {
        position: absolute;
        content: " ";
        top: 0;
        right: 0;
        bottom: 0;
        width: 4px;
        background-color: $amp-blue;
        animation-name: sidebarAccentHeightAnimation;
        animation-duration: 0.25s;
        animation-iteration-count: 1;
      }
    }

    p {
      font-weight: 100;
      margin-left: 12px;
      align-self: center;
    }

    .user-hint {
      position: absolute;
      left: calc(100% + 8px);
      background-color: white;
      padding: 8px;
      height: 34px;
      width: auto;
      white-space: nowrap;
      top: 50%;
      margin-top: -17px;
      display: none;
      border-radius: 4px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.125);
      z-index: 999;

      &:before {
        position: absolute;
        content: " ";
        width: 0;
        height: 0;
        top: 9px;
        left: -8px;
        border-top: solid 8px transparent;
        border-bottom: solid 8px transparent;
        border-right: solid 8px white;
      }
    }
  }
}
